import React from "react"

import BlogSectionFirst from "./blogSections/BlogSectionFirst"

const sections = [{ component: (key: number, className: string) => <BlogSectionFirst className={className} key={key} /> }]

const BlogSections = () => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-blog sova-section-blog_${i}`))}</>
}

BlogSections.displayName = "BlogSections"

export default BlogSections
