import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { usePageContext } from "../../../components/pageContext/pageContext"
import { useStoreon } from "../../../store/store"
import Img from "gatsby-image"
import Section from "../../../components/section/Section"
import downArrowIcon from "../../../blog/assets/mainBlogPage/seeMore-btn-icon-for-blogPage-sova.ai.svg"

const BlogSectionFirstConfig = {
  heading: "Blog",
}

const BlogSectionFirst = ({ className }: { className: string }) => {
  const { lang, locale } = usePageContext() as any
  const { dispatch } = useStoreon()
  const { heading } = BlogSectionFirstConfig
  const {
    settings: {
      blogTagFilter: { tagSelected, posts, newPostsQuantity },
    },
  } = useStoreon("settings")

  const {
    allStrapiArticle: { edges },
  } = useStaticQuery(
    graphql`
      query {
        allStrapiArticle(sort: { fields: created_at }) {
          edges {
            node {
              content {
                content
                language
                previewText
                title
                link
                previewImg {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                author {
                  name
                  authorImg {
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                blog_tags {
                  name
                }
              }
              created_at(formatString: "lll")
            }
          }
          totalCount
        }
      }
    `
  )

  const allPostsFilteredByLang = edges.map(({ node }: any) => {
    const content = node.content.map((elem: any) => {
      return { ...elem, date: node.created_at }
    })
    return content.find((elem: any) => {
      return elem.language === lang
    })
  })

  useEffect(() => {
    dispatch("initPostsList", allPostsFilteredByLang)
  }, [])

  useEffect(() => {
    if (tagSelected) {
      dispatch(
        "updatePostsList",
        allPostsFilteredByLang.filter((post: any) => {
          return post.blog_tags.find(({ name }: any) => name === tagSelected)
        })
      )
    }
    if (!tagSelected) {
      dispatch("updatePostsList", allPostsFilteredByLang)
    }
  }, [tagSelected])

  const loadMorePosts = () => {
    dispatch("updateNewPostsAmount", 3)
  }

  const postsList = (arr: any, className: string) => {
    return arr.map((post: any, key: number) => (
      <Link to={`/blog${locale + post.link}`} key={key} className={`sova-section-blog_post sova-section_blog-post-${className} sova-section_blog-post-${className}-${key}`}>
        <div className={`sova-section-container sova-section-container_blog-postContainer-${key}`}>
          {post.previewImg && <Img fluid={post.previewImg.childImageSharp.fluid} className={`sova-section_blog-postImg sova-section_blog-postImg-${className}-${key}`} />}
          <div className={`sova-section-container sova-section-container_blog-text sova-section-container_blog-text-${key}`}>
            {post.blog_tags.map(
              (tag: any, key: number) =>
                key === 0 && (
                  <p className="sova-section-child sova-section-child_blog sova-section-child_blog-postTag sova-p-tiny" key={key}>
                    {tag.name}
                  </p>
                )
            )}
            <h3 className={`sova-section-child sova-h3 sova-section-child_blog sova-section-child_blog-postTitle sova-section-child_blog-postTitle-${className}-${key}`}>{post.title}</h3>
            <p className={`sova-section-child sova-section-child_blog sova-section-child_blog-postPreview sova-section-child_blog-postPreview-${className}-${key} sova-p-tiny`}>{post.previewText}</p>
            <div className="sova-section-container sova-section-container_row sova-section-container_blog-authorInfo">
              {post.author.authorImg && <Img fluid={post.author.authorImg.childImageSharp.fluid} className={`sova_blog-authorImg sova_blog-authorImg-${className}-${key}`} />}
              <div className="sova-section-container sova-section-container_blog-authorInfo-text">
                <p className="sova-p-tiny">{post.author.name}</p>
                <p className="sova-p-tiny">{post.date}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    ))
  }

  return (
    <Section className={`${className} sova-section-without-margin-bottom`}>
      <div className="sova-section-container sova-section-container_blog-main">
        <h2 className="sova-section-child sova-h2 sova-h2_blog-heading">{heading}</h2>
        <div className="sova-section-container sova-section-container_row sova-section-container_blog-1">
          {postsList(
            posts.filter((_el: any, key: number) => key <= 2),
            "upper-block"
          )}
          {/* <div className="sova-section-container sova-section-container_blog-subscribtionForm">
            <img src={subscrImg} className="sova-section-child sova-section-child_blog-subscribtionForm-img" alt="Illustration for the subscribtion field of the blog page - SOVA.ai" />
            <p className="sova-section-child sova-section-child_blog-subscribtionForm-heading">Join our Newsletter</p>
            <p className="sova-section-child sova-section-child_blog-subscribtionForm-text">Stay up to date with the latest news from the SOVA community</p>
            <input type="text" className="sova-input sova-input_article sova-p-tiny" placeholder="E-mail" />
            <button className="sova-btn sova-btn_article sova-btn_secondary sova-btn_small ">Subscribe</button>
          </div> */}
        </div>
        <div className="sova-section-container sova-section-container_row sova-section-container_blog-2">
          {postsList(posts.filter((_el: any, key: number) => key >= 3).slice(0, newPostsQuantity), "lower-block")}
        </div>
      </div>

      <button onClick={() => loadMorePosts()} className="sova-btn sova-btn_secondary sova-btn_blog-loadMoreBtn">
        <div className="sova-section-container sova-section-container_row sova-section-container_blog-loadMoreBtn">
          <h2 className="sova-h2">See more</h2>
          <img src={downArrowIcon} className="sova-icon sova-icon_blog-loadMoreBtn" alt="Arrow icon" />
        </div>
      </button>
    </Section>
  )
}

BlogSectionFirst.displayName = "BlogSectionFirst"

export default BlogSectionFirst
