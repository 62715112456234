import React from "react"

import BlogLayout from "../blog/layout/blogLayout"
import SEO from "../components/SEO/seo"
import BlogSections from "../pagesStructures/blog/blogSections"

const Blog = () => {
  return (
    <BlogLayout>
      <SEO title="Blog" />
      <BlogSections />
    </BlogLayout>
  )
}

export default Blog
